.map-container {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.leaflet-container {
  width: 100%; 
  height: 100%;
}

.Mapview-Row {
  height: 100%;
}

.MapView-Col {
  padding: 0px;
  max-height: 100%;
}

.Mapview-Table {
  height: 100%;
  max-height: 100%;
  margin: 0px;
}

.Mapview-Table thead th { 
  position: sticky; 
  top: 0; 
  z-index: 1;
}

.Mapview-Table tr td { 
  background-color: #ececec; 
  border: 0; 
  padding: 4px;
  height: 80px;
}

.Mapview-Table tr th { 
  background-color: #ececec; 
  border: 0;
}

.Mapview-Table button { 
  margin: 5px; 
}

.Mapview-Table hr {
  margin: 0px;
}

.Mapview-Table p {
  margin: 0px;
}

.Maview-Table-Container {
  overflow: auto;
  max-height: 100%;
}

.Mapview-Badge {
  margin: 0.1rem;
  font-size: 0.9rem;
}

.Mapview-Legend {
  position: absolute;
  bottom: 25px;
  right: 10px;
  border: 1px solid black; /* Removed single quotes around property values */
  border-radius: 8px; /* Removed single quotes */
  background: white;
  z-index: 1000; /* Removed single quotes */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px; /* Removed single quotes */
}