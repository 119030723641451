#HomePage-Image { 
    width: 100%;
    height: 100%;
    background-image: url('./assets/homepage-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#HomePage-Image::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.75);
}